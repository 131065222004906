var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "modal " + _vm.saveModalStatus }, [
      _vm.$route.path !== "/contents/questionnaires/new"
        ? _c(
            "div",
            {
              staticClass: "close_modal",
              on: {
                click: function($event) {
                  return _vm.setVisibility(false)
                }
              }
            },
            [_vm._v("\n      ×\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: "modal-content has-background-white " + _vm.getSaveClassString
        },
        [
          _c("div", { staticClass: "content" }, [
            _vm.$route.path === "/contents/questionnaires/new"
              ? _c("div", [_vm._m(0)])
              : _c(
                  "div",
                  {
                    staticClass:
                      "\n          content\n          has-text-centered"
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm.isDraft
                      ? _c(
                          "div",
                          { staticClass: "block save_items has-text-left" },
                          [
                            _vm._v(
                              "\n            ※下書き状態のため、配信の作成は行えません。\n          "
                            )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "block save_items has-text-left" },
                          [
                            _vm._v(
                              "\n            まだユーザーには配信されていません。続けて配信の作成を行いますか？\n          "
                            )
                          ]
                        ),
                    _vm._v(" "),
                    !_vm.isDraft
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              class:
                                "dropdown button_bgcolor_info mb-3 " +
                                _vm.deliveryDropDownStatus,
                              on: { click: _vm.deliveryDropDownClick }
                            },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { id: "dropdown-menu", role: "menu" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-content has-text-left"
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.createDelivery(
                                                _vm.MODE_CREATE_RICH_CONTENTS
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    リッチコンテンツ\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.createDelivery(
                                                _vm.MODE_CREATE_RICH_MENU
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    リッチメニュー\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.createDelivery(
                                                _vm.MODE_CREATE_TEXT
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    テキスト\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button button_bgcolor_light",
                          on: {
                            click: function($event) {
                              return _vm.createDelivery(_vm.MODE_BACK_TO_LIST)
                            }
                          }
                        },
                        [_vm._v("\n              一覧に戻る\n            ")]
                      )
                    ])
                  ]
                )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block mt-8 text-center grey-600--text" }, [
      _c("div", { staticClass: "text-h4" }, [
        _vm._v("\n              新規作成が完了しました\n            ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "body" }, [
        _vm._v("\n              アンケート一覧画面に戻ります\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block mt-6" }, [
      _c("h1", { staticClass: "has-text-centered" }, [
        _vm._v("\n              保存しました\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button button_bgcolor_info",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu" }
        },
        [
          _c("span", { staticClass: "has-text-white" }, [
            _vm._v("このコンテンツで配信作成")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "material-icons has-text-white ml-2" }, [
            _vm._v("arrow_drop_down")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }