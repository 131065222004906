export const QUESTIONNAIRES_TYPE = {
  TITLE: 0,
  IMAGE: 1,
  QUESTION: 2,
};

export const QUESTIONNAIRES_ITEM_TYPE = {
  TEXT_ROW: 0,
  TEXT_PARAGRAPH: 1,
  CHECKBOX_TEXT: 2,
  RADIO_TEXT: 3,
  CHECKBOX_IMAGE: 4,
  RADIO_IMAGE: 5,
  PULLDOWN: 6,
  SCALE: 7,
  DATE: 8,
};

export const QUESTION_TEMPLATE_BLANK = {
  id: null,
  campaign_id: null,
  medium_content_id: null,
  medium_content_url: "",
  question: "",
  kind: "",
  order: null,
  is_required: false,
  is_enable: true,
  description: "",
  scale_from: "0",
  scale_to: "10",
  scale_from_text: "",
  scale_to_text: "",
  item_kind: 0,
  item_text: "",
  date: null,
  questionnaire_choices: [],
  branchParents: [],
  branch: {
    id: "",
    index: null,
    uid: "",
  },
  status: ""
};

export const QUESTION_TEMPLATES = [
  {
    index: 0,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.TEXT_ROW, QUESTIONNAIRES_ITEM_TYPE.TEXT_ROW],
    text: "氏名",
    titles: ["氏名(姓)", "氏名(名)"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [],
  },
  {
    index: 1,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.TEXT_ROW, QUESTIONNAIRES_ITEM_TYPE.TEXT_ROW],
    text: "氏名(カナ)",
    titles: ["氏名(セイ)", "氏名(メイ)"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [],
  },
  {
    index: 2,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.TEXT_ROW],
    text: "電話番号",
    titles: ["電話番号を入力してください"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [],
  },
  {
    index: 3,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.TEXT_ROW],
    text: "メールアドレス",
    titles: ["メールアドレスを入力してください"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [],
  },
  {
    index: 4,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.PULLDOWN],
    text: "年代",
    titles: ["年代を選択してください"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [
      { is_enable: true, text: "10代以下", branches: [] },
      { is_enable: true, text: "20代", branches: [] },
      { is_enable: true, text: "30代", branches: [] },
      { is_enable: true, text: "40代", branches: [] },
      { is_enable: true, text: "50代", branches: [] },
      { is_enable: true, text: "60代", branches: [] },
      { is_enable: true, text: "70代", branches: [] },
      { is_enable: true, text: "80代", branches: [] },
      { is_enable: true, text: "90代以上", branches: [] },
    ]
  },
  {
    index: 5,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.DATE],
    text: "生年月日",
    titles: ["生年月日を入力してください"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [],
  },
  {
    index: 6,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.PULLDOWN],
    text: "性別",
    titles: ["性別を入力してください"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [
      { is_enable: true, text: "男性", branches: [] },
      { is_enable: true, text: "女性", branches: [] },
      { is_enable: true, text: "無回答", branches: [] },
      { is_enable: true, text: "その他", branches: [] },
    ]
  },
  {
    index: 7,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.PULLDOWN],
    text: "都道府県",
    titles: ["都道府県を選択してください"],
    isRequired:  false,
    isEnable: true,
    questionnaireChoices: [
      { is_enable: true, text: "北海道", branches: [] },
      { is_enable: true, text: "青森県", branches: [] },
      { is_enable: true, text: "岩手県", branches: [] },
      { is_enable: true, text: "宮城県", branches: [] },
      { is_enable: true, text: "秋田県", branches: [] },
      { is_enable: true, text: "山形県", branches: [] },
      { is_enable: true, text: "福島県", branches: [] },
      { is_enable: true, text: "茨城県", branches: [] },
      { is_enable: true, text: "栃木県", branches: [] },
      { is_enable: true, text: "群馬県", branches: [] },
      { is_enable: true, text: "埼玉県", branches: [] },
      { is_enable: true, text: "千葉県", branches: [] },
      { is_enable: true, text: "東京都", branches: [] },
      { is_enable: true, text: "神奈川", branches: [] },
      { is_enable: true, text: "新潟県", branches: [] },
      { is_enable: true, text: "富山県", branches: [] },
      { is_enable: true, text: "石川県", branches: [] },
      { is_enable: true, text: "福井県", branches: [] },
      { is_enable: true, text: "山梨県", branches: [] },
      { is_enable: true, text: "長野県", branches: [] },
      { is_enable: true, text: "岐阜県", branches: [] },
      { is_enable: true, text: "静岡県", branches: [] },
      { is_enable: true, text: "愛知県", branches: [] },
      { is_enable: true, text: "三重県", branches: [] },
      { is_enable: true, text: "滋賀県", branches: [] },
      { is_enable: true, text: "京都府", branches: [] },
      { is_enable: true, text: "大阪府", branches: [] },
      { is_enable: true, text: "兵庫県", branches: [] },
      { is_enable: true, text: "奈良県", branches: [] },
      { is_enable: true, text: "和歌山", branches: [] },
      { is_enable: true, text: "鳥取県", branches: [] },
      { is_enable: true, text: "島根県", branches: [] },
      { is_enable: true, text: "岡山県", branches: [] },
      { is_enable: true, text: "広島県", branches: [] },
      { is_enable: true, text: "山口県", branches: [] },
      { is_enable: true, text: "徳島県", branches: [] },
      { is_enable: true, text: "香川県", branches: [] },
      { is_enable: true, text: "愛媛県", branches: [] },
      { is_enable: true, text: "高知県", branches: [] },
      { is_enable: true, text: "福岡県", branches: [] },
      { is_enable: true, text: "佐賀県", branches: [] },
      { is_enable: true, text: "長崎県", branches: [] },
      { is_enable: true, text: "熊本県", branches: [] },
      { is_enable: true, text: "大分県", branches: [] },
      { is_enable: true, text: "宮崎県", branches: [] },
      { is_enable: true, text: "鹿児島", branches: [] },
      { is_enable: true, text: "沖縄県", branches: [] },
    ]
  },
  {
    index: 8,
    itemkinds: [QUESTIONNAIRES_ITEM_TYPE.SCALE],
    text: "NPS",
    titles: ["知人に薦めたいと思いますか？"],
    isRequired:  false,
    isEnable: true,
    scaleFrom: "1",
    scaleTo: "10",
    scaleFromText: "思わない",
    scaleToText: "非常にそう思う",
    questionnaireChoices: [],
  },
];

export const QUESTIONNAIRES_ITEM_KINDS = [
  {
    index: 1,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.TEXT_ROW,
    text: "テキスト（行）",
    icon: "text-short",
    name: "text_row",
  },
  {
    index: 2,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.TEXT_PARAGRAPH,
    text: "テキスト（段落）",
    icon: "text",
    name: "text_paragraph",
  },
  {
    index: -1,
    isDivider: true,
  },
  {
    index: 3,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.CHECKBOX_TEXT,
    text: "チェックボックス(テキスト)",
    icon: "checkbox-outline",
    name: "checkbox_text",
  },
  {
    index: 4,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.RADIO_TEXT,
    text: "ラジオボタン(テキスト)",
    icon: "radiobox-marked",
    name: "radio_text",
  },
  {
    index: 5,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.CHECKBOX_IMAGE,
    text: "チェックボックス(画像)",
    icon: "checkbox-outline",
    name: "checkbox_image",
  },
  {
    index: 6,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.RADIO_IMAGE,
    text: "ラジオボタン(画像)",
    icon: "radiobox-marked",
  },
  {
    index: -2,
    isDivider: true,
  },
  {
    index: 7,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.PULLDOWN,
    text: "プルダウン",
    icon: "arrow-down-drop-circle-outline",
    name: "pulldown",
  },
  {
    index: 8,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.SCALE,
    text: "スケール",
    icon: "ray-start-vertex-end",
    name: "scale",
  },
  {
    index: 9,
    itemkind: QUESTIONNAIRES_ITEM_TYPE.DATE,
    text: "日付",
    icon: "calendar-range",
    name: "date",
  },
];
