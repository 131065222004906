<template>
  <div>
    <div :class="`modal ${saveModalStatus}`">
      <div
        v-if="$route.path !== '/contents/questionnaires/new'"
        class="close_modal"
        @click="setVisibility(false)"
      >
        ×
      </div>
      <div class="modal-background" />
      <div :class="`modal-content has-background-white ${getSaveClassString}`">
        <div class="content">
          <div
            v-if="$route.path === '/contents/questionnaires/new'"
          >
            <div class="block mt-8 text-center grey-600--text">
              <div class="text-h4">
                新規作成が完了しました
              </div>
              <div class="body">
                アンケート一覧画面に戻ります
              </div>
            </div>
          </div>
          <div
            v-else
            class="
            content
            has-text-centered"
          >
            <div class="block mt-6">
              <h1 class="has-text-centered">
                保存しました
              </h1>
            </div>
            <div
              v-if="isDraft"
              class="block save_items has-text-left"
            >
              ※下書き状態のため、配信の作成は行えません。
            </div>
            <div
              v-else
              class="block save_items has-text-left"
            >
              まだユーザーには配信されていません。続けて配信の作成を行いますか？
            </div>
            <div v-if="!isDraft">
              <div
                :class="`dropdown button_bgcolor_info mb-3 ${deliveryDropDownStatus}`"
                @click="deliveryDropDownClick"
              >
                <div class="dropdown-trigger">
                  <button
                    class="button button_bgcolor_info"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  >
                    <span class="has-text-white">このコンテンツで配信作成</span>
                    <span class="material-icons has-text-white ml-2">arrow_drop_down</span>
                  </button>
                </div>
                <div
                  id="dropdown-menu"
                  class="dropdown-menu"
                  role="menu"
                >
                  <div class="dropdown-content has-text-left">
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="createDelivery(MODE_CREATE_RICH_CONTENTS)"
                    >
                      リッチコンテンツ
                    </a>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="createDelivery(MODE_CREATE_RICH_MENU)"
                    >
                      リッチメニュー
                    </a>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="createDelivery(MODE_CREATE_TEXT)"
                    >
                      テキスト
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <button
                class="button button_bgcolor_light"
                @click="createDelivery(MODE_BACK_TO_LIST)"
              >
                一覧に戻る
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propsDraft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deliveryDropDownStatus: "",
      saveModalStatus: "",
      isDraft: this.propsDraft,
      MODE_BACK_TO_LIST: -1,
      MODE_CREATE_RICH_CONTENTS: 0,
      MODE_CREATE_RICH_MENU: 1,
      MODE_CREATE_TEXT: 2,
    };
  },

  // created(): {},
  // mounted(): {},
  // watch: {},
  computed: {
    getSaveClassString() {
      return this.isDraft ? "no_delivery" : "delivery";
    },

    getDeliveryClass() {
      if (this.isDraft) {
        return "is-hidden";
      } else {
        return "";
      }
    },
  },

  methods: {
    // モーダルの表示/非表示切り替え
    setVisibility(value) {
      this.saveModalStatus = value ? "is-active" : "";
    },

    // 「保存しました」モーダルの「このコンテンツで配信作成」をクリックした時の処理。
    // リストの表示/非表示を切り替える
    deliveryDropDownClick() {
      this.deliveryDropDownStatus = this.deliveryDropDownStatus ? "" : "is-active";
    },

    // 「このコンテンツで配信作成」のリスト、または「コンテンツ一覧に戻る」
    // クリック時の処理。(modeの値はHTML部分を参照)
    createDelivery(mode) {
      this.$emit("emitted", mode);
      this.setVisibility(false);
    },

    // 配信作成の項目を表示するか決める
    setDraft(value) {
      this.isDraft = value;
    }
  }
};
</script>

<style scoped>
.button_bgcolor_info {
  background-color: #2F80ED;
}

.button_bgcolor_light {
  background-color: #F6F6F6;
}

.modal .modal-content {
  width: 520px;
  border-radius: 4px;
}

.modal .modal-content .dropdown {
  border-radius: 4px;
}

.modal .modal-content .button {
  border-radius: 4px;
}

.modal .dropdown-trigger .button, .modal .dropdown-menu .dropdown-content, .modal button.button_bgcolor_light {
  width: 300px;
}

.modal .modal-content.delivery {
  height: 350px;
}

.modal .modal-content.no_delivery {
  padding-bottom: 2rem;
}

/* モーダル右上の「×」ボタン */
.modal .close_modal {
  width: 30px;
  height: 30px;
  top: 0;
  left: 259px;
  text-align: center;
  background-color:rgba(255, 255, 255, 1);
  border-radius: 20px;
  z-index: 2147483647;
  font-weight: bold;
  transform: translateY(15px);
  position: relative;
  box-shadow: -0.25px 0.25px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.save_items {
  width: 70%;
  margin: 0 auto;
}

</style>
